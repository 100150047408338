const createLoanApplication = {
  namespaced: true,
  state: {
    // currentState
    currentState: "step1",
    // step2Tab
    step2Tab: "upload-automatically",
    // step2Key
    step2Key: "upload-automatically",
    // step2Href
    step2Href: "#upload-automatically",
    // loanAmount
    loanAmount: 1700,

    // Expenses
    medicalExpense: null,
    // Accomodation
    accomodationExpense: null,
    // Groceries And Household
    groceriesAndHouseholdExpense: null,
    // Transport
    transportExpense: null,
    // Education
    educationExpense: null,
    // Maintenance
    maintenanceExpense: null,
    // Water And Electricity
    waterAndElectricityExpense: null,
    // Loan Repayment
    loanRepaymentExpense: null,

    // Gross Monthly Income
    grossMonthlyIncome: null,
    // Net Monthly Income
    netMonthlyIncome: null,
    // Indicative Disposable
    indicativeDisposable: null,
  },

  mutations: {
    setDefaultState(state) {
      state.currentState = "step1";
      state.step2Tab = "upload-automatically";
      state.step2Key = "upload-automatically";
      state.step2Href = "#upload-automatically";
      state.loanAmount = 1700;

      state.medicalExpense = null;
      state.accomodationExpense = null;
      state.groceriesAndHouseholdExpense = null;
      state.transportExpense = null;
      state.educationExpense = null;
      state.maintenanceExpense = null;
      state.waterAndElectricityExpense = null;
      state.loanRepaymentExpense = null;

      state.grossMonthlyIncome = null;
      state.netMonthlyIncome = null;
      state.indicativeDisposable = null;
    },
    // setCurrentState
    setCurrentState(state, payload) {
      state.currentState = payload;
    },
    // setStep2Tab
    setStep2Tab(state, payload) {
      state.step2Tab = payload;
    },
    // setStep2Key
    setStep2Key(state, payload) {
      state.step2Key = payload;
    },
    // setStep2Href
    setStep2Href(state, payload) {
      state.step2Href = payload;
    },
    // setLoanAmount
    setLoanAmount(state, payload) {
      state.loanAmount = payload;
    },
    // setMedicalExpense
    setMedicalExpense(state, payload) {
      state.medicalExpense = payload;
    },
    // setAccomodationExpense
    setAccomodationExpense(state, payload) {
      state.accomodationExpense = payload;
    },
    // groceriesAndHouseholdExpense
    setGroceriesAndHouseholdExpense(state, payload) {
      state.groceriesAndHouseholdExpense = payload;
    },
    // transportExpense
    setTransportExpense(state, payload) {
      state.transportExpense = payload;
    },
    // educationExpense
    setEducationExpense(state, payload) {
      state.educationExpense = payload;
    },
    // maintenanceExpense
    setMaintenanceExpense(state, payload) {
      state.maintenanceExpense = payload;
    },
    // waterAndElectricityExpense
    setWaterAndElectricityExpense(state, payload) {
      state.waterAndElectricityExpense = payload;
    },
    // loanRepaymentExpense
    setLoanRepaymentExpense(state, payload) {
      state.loanRepaymentExpense = payload;
    },

    //
    // loanRepaymentExpense
    setGrossMonthlyIncome(state, payload) {
      state.grossMonthlyIncome = payload;
    },
    // netMonthlyIncome
    setNetMonthlyIncome(state, payload) {
      state.netMonthlyIncome = payload;
    },
    // indicativeDisposable
    setIndicativeDisposable(state) {
      if (
        state.medicalExpense !== null &&
        state.accomodationExpense !== null &&
        state.groceriesAndHouseholdExpense !== null &&
        state.transportExpense !== null &&
        state.educationExpense !== null &&
        state.maintenanceExpense !== null &&
        state.waterAndElectricityExpense !== null &&
        state.loanRepaymentExpense !== null &&
        state.grossMonthlyIncome !== null &&
        state.netMonthlyIncome !== null &&
        state.medicalExpense !== null
      ) {
        state.indicativeDisposable =
          parseFloat(state.netMonthlyIncome) -
          (parseFloat(state.medicalExpense) +
            parseFloat(state.accomodationExpense) +
            parseFloat(state.groceriesAndHouseholdExpense) +
            parseFloat(state.transportExpense) +
            parseFloat(state.educationExpense) +
            parseFloat(state.maintenanceExpense) +
            parseFloat(state.waterAndElectricityExpense) +
            parseFloat(state.loanRepaymentExpense));
      } else {
        state.indicativeDisposable = null;
      }
    },
  },

  actions: {},

  getters: {
    // getCurrentState
    getCurrentState(state) {
      return state.currentState;
    },
    // getStep2Tab
    getStep2Tab(state) {
      return state.step2Tab;
    },
    // getStep2Key
    getStep2Key(state) {
      return state.step2Key;
    },
    // getStep2Href
    getStep2Href(state) {
      return state.step2Href;
    },
    // getLoanAmount
    getLoanAmount(state) {
      return state.loanAmount;
    },
    // medicalExpense
    getMedicalExpense(state) {
      return state.medicalExpense;
    },
    // accomodationExpense
    getaccomodationExpense(state) {
      return state.accomodationExpense;
    },
    // groceriesAndHouseholdExpense
    getGroceriesAndHouseholdExpense(state) {
      return state.groceriesAndHouseholdExpense;
    },
    // transportExpense
    getTransportExpense(state) {
      return state.transportExpense;
    },
    // educationExpense
    getEducationExpense(state) {
      return state.educationExpense;
    },
    // maintenanceExpense
    getMaintenanceExpense(state) {
      return state.maintenanceExpense;
    },
    // waterAndElectricityExpense
    getWaterAndElectricityExpense(state) {
      return state.waterAndElectricityExpense;
    },
    // loanRepaymentExpense
    getLoanRepaymentExpense(state) {
      return state.loanRepaymentExpense;
    },
    // grossMonthlyIncome
    getGrossMonthlyIncome(state) {
      return state.grossMonthlyIncome;
    },
    // netMonthlyIncome
    getNetMonthlyIncome(state) {
      return state.netMonthlyIncome;
    },
    // indicativeDisposable
    getIndicativeDisposable(state) {
      return state.indicativeDisposable;
    },
  },
};

export default createLoanApplication;
