import axios from "../../plugins/axios";

const signDocument = {
  namespaced: true,

  state: {
    result: "",
    signedDocumentData: "",
    fileName: "",
    attachmentId: "",
    otp: "",
  },

  mutations: {
    // result
    setResult(state, payload) {
      state.result = payload;
    },

    // signedDocumentData
    setSignedDocumentData(state, payload) {
      state.signedDocumentData = payload;
    },

    // fileName
    setFileName(state, payload) {
      state.fileName = payload;
    },

    // attachmentId
    setAttachmentId(state, payload) {
      state.attachmentId = payload;
    },

    // otp
    setOtp(state, payload) {
      state.otp = payload;
    },

    // resetState
    resetState(state) {
      state.result = "";
      state.signedDocumentData = "";
      state.fileName = "";
      state.attachmentId = "";
      state.otp = "";
    },
  },

  actions: {
    // sendApiSignDocument
    async sendApiSignDocument({ commit, rootState }) {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      try {
        const res = await axios.post("sign-document", {
          idnumber: rootState.auth.idnumber,
          session_key: rootState.auth.session_key,
          document_signature_otp: rootState.signDocument.otp,
          document_name: "AgreementDocument",
        });

        if (res.status == 200) {
          const data = res.data;

          response.status = res.status;
          response.data = res.data;

          // result
          if ("result" in data) {
            commit("setResult", data.result);
          }

          // signed_document_data
          if ("signed_document_data" in data) {
            commit("setSignedDocumentData", data.signed_document_data);
          }

          // filename
          if ("filename" in data) {
            commit("setFileName", data.filename);
          }

          // attachment_id
          if ("attachment_id" in data) {
            commit("setAttachmentId", data.attachment_id);
          }
        }
      } catch (error) {
        if (error && error.response && error.response.status) {
          response.status = error.response.status;
        }

        if (error && error.response && error.response.data) {
          response.data = error.response.data;
        }
      }

      return response;
    },
  },

  getters: {
    // result
    getResult(state) {
      return state.result;
    },

    // signedDocumentData
    getSignedDocumentData(state) {
      return state.signedDocumentData;
    },

    // fileName
    getFileName(state) {
      return state.fileName;
    },

    // attachmentId
    getAttachmentId(state) {
      return state.attachmentId;
    },

    // otp
    getOtp(state) {
      return state.otp;
    },
  },
};

export default signDocument;
