import axios from "axios";

const baseDomain = process.env.VUE_APP_API_BASE_URL;

const baseURL = `${baseDomain}`;

const instance = axios.create({
  baseURL,
});

instance.defaults.headers = {
  ApiKey: process.env.VUE_APP_API_KEY,
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

export default instance;
