import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import VueMeta from "vue-meta";

Vue.use(VueRouter);

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

const routes = [
  // New public pages
  {
    path: "/",
    component: () => import("@/layouts/default"),
    // eslint-disable-next-line no-sparse-arrays
    children: [
      {
        path: "",
        name: "HomePage",
        component: () =>
          import(/* webpackChunkName: "HomePage" */ "@/views/public/HomePage"),
      },
      {
        path: "login",
        name: "LoginPage",
        component: () =>
          import(
            /* webpackChunkName: "LoginPage" */ "@/views/public/LoginPage"
          ),
      },
      {
        path: "registration",
        name: "RegistrationPage",
        component: () =>
          import(
            /* webpackChunkName: "RegistrationPage" */ "@/views/public/RegistrationPage"
          ),
      },
      {
        path: "responsible-lending",
        name: "ResponsibleLendingPage",
        component: () =>
          import(
            /* webpackChunkName: "ResponsibleLendingPage" */ "@/views/public/ResponsibleLendingPage"
          ),
      },
      {
        path: "about-lime",
        name: "AboutLimePage",
        component: () =>
          import(
            /* webpackChunkName: "AboutLimePage" */ "@/views/public/AboutLimePage"
          ),
      },
      {
        path: "faqs",
        name: "FAQsPage",
        component: () =>
          import(/* webpackChunkName: "FAQsPage" */ "@/views/public/FAQsPage"),
      },
      {
        path: "privacy-policy",
        name: "PrivacyPolicyPage",
        component: () =>
          import(
            /* webpackChunkName: "PrivacyPolicyPage" */ "@/views/public/PrivacyPolicyPage"
          ),
      },
      {
        path: "404",
        name: "404Page",
        component: () =>
          import(/* webpackChunkName: "404Page" */ "@/views/errors/404Page"),
      },
      {
        path: "500",
        name: "500Page",
        component: () =>
          import(/* webpackChunkName: "500Page" */ "@/views/errors/500Page"),
      },
      {
        path: "password-recovery",
        name: "PasswordRecoveryPage",
        component: () =>
          import(
            /* webpackChunkName: "PasswordRecoveryPage" */ "@/views/public/PasswordRecoveryPage"
          ),
      },
      ,
      {
        path: "article",
        name: "article",
        component: () =>
          import(/* webpackChunkName: "500Page" */ "@/views/errors/500Page"),
      },
      {
        path: "unsubscribe",
        name: "DashboardUnsubscribePage",
        component: () =>
          import(
            /* webpackChunkName: "DashboardRefusalPage" */ "@/views/dashboard/Unsubscribe"
          ),
      },
    ],
  },

  // Create Loan Application Page
  {
    path: "/",
    component: () => import("@/layouts/default"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "create-loan-application",
        name: "CreateLoanApplicationPage",
        component: () =>
          import(
            /* webpackChunkName: "CreateLoanApplicationPage" */ "@/views/public/CreateLoanApplicationPage"
          ),
      },
    ],
  },

  // new dashboard pages
  {
    path: "/dashboard/",
    component: () => import("@/layouts/dashboard"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        redirect: "/dashboard/my-loan",
        name: "DashboardPage",
        component: () =>
          import(
            /* webpackChunkName: "DashboardPage" */ "@/views/dashboard/DashboardHomePage"
          ),
      },
      {
        path: "home",
        redirect: "/dashboard/my-loan",
        name: "DashboardHomePage",
        component: () =>
          import(
            /* webpackChunkName: "DashboardHomePage" */ "@/views/dashboard/DashboardHomePage"
          ),
      },
      {
        path: "my-loan",
        name: "DashboardMyLoanPage",
        component: () =>
          import(
            /* webpackChunkName: "DashboardMyLoanPage" */ "@/views/dashboard/MyLoanPage"
          ),
      },
      {
        path: "personal-data",
        name: "DashboardPersonalDataPage",
        component: () =>
          import(
            /* webpackChunkName: "DashboardPersonalDataPage" */ "@/views/dashboard/PersonalDataPage"
          ),
      },
      {
        path: "refusal",
        name: "DashboardRefusalPage",
        component: () =>
          import(
            /* webpackChunkName: "DashboardRefusalPage" */ "@/views/dashboard/RefusalPage"
          ),
      },
      {
        path: "Unsubscribe",
        name: "DashboardUnsubscribePage",
        component: () =>
          import(
            /* webpackChunkName: "DashboardRefusalPage" */ "@/views/dashboard/Unsubscribe"
          ),
      },
      {
        path: "signing-loan-agreement",
        name: "DashboardSigningLoanAgreementPage",
        component: () =>
          import(
            /* webpackChunkName: "DashboardSigningLoanAgreementPage" */ "@/views/dashboard/SigningLoanAgreementPage"
          ),
      },
      {
        path: "debit-order-approval",
        name: "DashboardDebitOrderApprovalPage",
        component: () =>
          import(
            /* webpackChunkName: "DashboardDebitOrderApprovalPage" */ "@/views/dashboard/DebitOrderApprovalPage"
          ),
      },
      {
        path: "waiting-for-money",
        name: "DashboardWaitingForMoneyPage",
        component: () =>
          import(
            /* webpackChunkName: "DashboardWaitingForMoneyPage" */ "@/views/dashboard/WaitingForMoneyPage"
          ),
      },
      {
        path: "waiting-for-approval",
        name: "DashboardWaitingForApprovalPage",
        component: () =>
          import(
            /* webpackChunkName: "DashboardWaitingForApprovalPage" */ "@/views/dashboard/WaitingForApprovalPage"
          ),
      },
    ],
  },

  // new dashboard pages
  {
    path: "/blogs/",
    component: () => import("@/layouts/blogs"),
    children: [
      {
        path: "",
        redirect: "/blogs/blog-page",
        name: "blogspage",
        component: () =>
          import(
            /* webpackChunkName: "DashboardPage" */ "@/views/dashboard/DashboardHomePage"
          ),
      },
      {
        path: "blog-page",
        name: "BlogPage",
        component: () =>
          import(/* webpackChunkName: "BlogPage" */ "@/views/blog/BlogPage"),
      },
      {
        path: "helpful-info-article",
        name: "HelpfulInfoArticle",
        component: () =>
          import(
            /* webpackChunkName: "BlogPage" */ "@/views/blog/helpful-information/HelpfulInfoArticle"
          ),
      },
      {
        path: "helpful-info-article-economy",
        name: "HelpfulInfoArticle3",
        component: () =>
          import(
            /* webpackChunkName: "BlogPage" */ "@/views/blog/helpful-information/HelpfulInfoArticle3.vue"
          ),
      },
      {
        path: "helpful-info-article-over-indebtedness",
        name: "HelpfulInfoArticle4",
        component: () =>
          import(
            /* webpackChunkName: "BlogPage" */ "@/views/blog/helpful-information/HelpfulInfoArticle4.vue"
          ),
      },
      {
        path: "helpful-info-article-economy-climate",
        name: "HelpfulInfoArticle5",
        component: () =>
          import(
            /* webpackChunkName: "BlogPage" */ "@/views/blog/helpful-information/HelpfulInfoArticle5.vue"
          ),
      },
      {
        path: "helpful-info-article-short-term-loans",
        name: "HelpfulInfoArticle6",
        component: () =>
          import(
            /* webpackChunkName: "BlogPage" */ "@/views/blog/helpful-information/HelpfulInfoArticle6.vue"
          ),
      },
      {
        path: "all-news",
        name: "AllNews",
        component: () =>
          import(/* webpackChunkName: "BlogPage" */ "@/views/blog/AllNews"),
      },
      {
        path: "company-news",
        name: "CompanyNews",
        component: () =>
          import(
            /* webpackChunkName: "BlogPage" */ "@/views/blog/company-news/CompanyNews"
          ),
      },
      {
        path: "company-news-article1",
        name: "CompanyNewsArticle1",
        component: () =>
          import(
            /* webpackChunkName: "BlogPage" */ "@/views/blog/company-news/CompanyNewsArticle1"
          ),
      },
      {
        path: "company-news-article2",
        name: "CompanyNewsArticle2",
        component: () =>
          import(
            /* webpackChunkName: "BlogPage" */ "@/views/blog/company-news/CompanyNewsArticle2"
          ),
      },
      {
        path: "company-news-article3",
        name: "CompanyNewsArticle3",
        component: () =>
          import(
            /* webpackChunkName: "BlogPage" */ "@/views/blog/company-news/CompanyNewsArticle3"
          ),
      },
      {
        path: "mfo-news",
        name: "MFONews",
        component: () =>
          import(/* webpackChunkName: "BlogPage" */ "@/views/blog/MFONews"),
      },
      {
        path: "helpful-information",
        name: "HelpfulInformation",
        component: () =>
          import(
            /* webpackChunkName: "BlogPage" */ "@/views/blog/helpful-information/HelpfulInformation"
          ),
      },
      {
        path: "financial-tips",
        name: "FinancialTips",
        component: () =>
          import(
            /* webpackChunkName: "BlogPage" */ "@/views/blog/financial-tips/FinancialTips"
          ),
      },
      {
        path: "financial-tips-article1",
        name: "FinancialTipsArticle1",
        component: () =>
          import(
            /* webpackChunkName: "BlogPage" */ "@/views/blog/financial-tips/FinancialTipsArticle1"
          ),
      },

      //============================================================================================================================================
      {
        path: "financial-tips-article2",
        name: "FinancialTipsArticle2",
        component: () =>
          import(
            /* webpackChunkName: "BlogPage" */ "@/views/blog/financial-tips/FinancialTipsArticle2"
          ),
      },

      {
        path: "financial-tips-article-life-and-finances",
        name: "FinancialTipsArticle3",
        component: () =>
          import(
            /* webpackChunkName: "BlogPage" */ "@/views/blog/financial-tips/FinancialTipsArticle3"
          ),
      },

      {
        path: "financial-tips-article-life-and-finances",
        name: "FinancialTipsArticle4",
        component: () =>
          import(
            /* webpackChunkName: "BlogPage" */ "@/views/blog/financial-tips/FinancialTipsArticle4"
          ),
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "404Page" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.auth.loggedIn) {
      next({ path: "/login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
