import axios from "axios";
import router from "../../router";

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

axios.defaults.headers = {
  ApiKey: process.env.VUE_APP_API_KEY,
};

const auth = {
  namespaced: true,

  state: {
    loggedIn: false,
    session_key: "",
    session_expires: "",
    idnumber: "",
    user: {},
    redirect: "",
  },

  mutations: {
    setLoggedIn(state, payload) {
      state.loggedIn = payload;
    },

    setSessionKey(state, payload) {
      state.session_key = payload;
    },

    setSessionExpires(state, payload) {
      state.session_expires = payload;
    },

    setIdNumber(state, payload) {
      state.idnumber = payload;
    },

    setUser(state, payload) {
      state.user = payload;
    },

    setRedirect(state, payload) {
      state.redirect = payload;
    },

    resetState(state) {
      state.loggedIn = false;
      state.session_key = "";
      state.session_expires = "";
      state.idnumber = "";
      state.user = {};
    },
  },

  actions: {
    // getApiUserDetails
    async getApiUserDetails({ commit, getters }) {
      try {
        const res = await axios.post("detail", {
          idnumber: getters.getIdNumber,
          session_key: getters.getSessionKey,
        });

        if (res.status == 200) {
          const data = res.data;

          commit("setUser", data);

          // session_expires
          if ("session_expires" in data) {
            commit("setSessionExpires", data.session_expires);
          }
          
        }
      } catch (error) {
        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401) {
          commit("resetState");
        }
      }
    },

    // logOutUser
    logOutUser({ commit, rootState }) {
      // createLoanApplication/setDefaultState
      commit("createLoanApplication/setDefaultState", null, {
        root: true,
      });

      // document/resetState
      commit("document/resetState", null, {
        root: true,
      });

      // generateSettlementQuote/resetState
      commit("generateSettlementQuote/resetState", null, {
        root: true,
      });

      // generateStatement/resetState
      commit("generateStatement/resetState", null, {
        root: true,
      });

      // initiateDebitOrder/resetState
      commit("initiateDebitOrder/resetState", null, {
        root: true,
      });

      // login/resetState
      commit("login/resetState", null, {
        root: true,
      });

      // registration/resetState
      commit("registration/resetState", null, {
        root: true,
      });

      // requestDocumentOTP/resetState
      commit("requestDocumentOTP/resetState", null, {
        root: true,
      });

      // retrieveAttachment/resetState
      commit("retrieveAttachment/resetState", null, {
        root: true,
      });

      // signDocument/resetState
      commit("signDocument/resetState", null, {
        root: true,
      });

      // truid/resetState
      commit("truid/resetState", null, {
        root: true,
      });

      // console.log("Log out user");

      // setRedirect
      commit("setRedirect", window.location.pathname);

      // resetState
      commit("resetState");

      // redirect //Jeremy made a not this is causing error
      router.push({
        name: "HomePage",
        query: { redirect: rootState.auth.redirect },
      });
    },
  },

  getters: {
    getLoggedIn(state) {
      return state.loggedIn;
    },

    getSessionKey(state) {
      return state.session_key;
    },

    getSessionExpires(state) {
      return state.session_expires;
    },

    getIdNumber(state) {
      return state.idnumber;
    },

    getUser(state) {
      return state.user;
    },

    getLoansCount(state) {
      if (
        state.user &&
        "loans" in state.user &&
        Array.isArray(state.user.loans)
      ) {
        return state.user.loans.length;
      } else {
        return 0;
      }
    },
  },
};

export default auth;
