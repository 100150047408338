import axios from "../../plugins/axios";

const initiateDebitOrder = {
  namespaced: true,

  state: {
    // numberOfRequest
    numberOfRequest: 0,

    // maxNumberOfRequest
    maxNumberOfRequest: 3,

    // result
    result: "",
  },

  mutations: {
    // numberOfRequest
    setNumberOfRequest(state, payload) {
      state.numberOfRequest = payload;
    },

    // maxNumberOfRequest
    setMaxNumberOfRequest(state, payload) {
      state.maxNumberOfRequest = payload;
    },

    // result
    setResult(state, payload) {
      state.result = payload;
    },

    // incrementNumberOfRequest
    incrementNumberOfRequest(state) {
      state.numberOfRequest += 1;
    },

    // resetState
    resetState(state) {
      state.numberOfRequest = 0;
      state.maxNumberOfRequest = 3;
      state.result = "";
    },
  },

  actions: {
    // sendInitiateDebitOrder
    async sendApiInitiateDebitOrder({ commit, rootState }) {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      try {
        const res = await axios.post("initiate-debit-order", {
          idnumber: rootState.auth.idnumber,
          session_key: rootState.auth.session_key,
        });

        if (res.status == 200) {
          const data = res.data;

          response.status = res.status;
          response.data = res.data;

          // result
          if ("result" in data) {
            commit("setResult", data.result);
          }
        }
      } catch (error) {
        if (error && error.response && error.response.status) {
          response.status = error.response.status;
        }

        if (error && error.response && error.response.data) {
          response.data = error.response.data;
        }
      } finally {
        // response.status
        if (response.status != null) {
          commit("incrementNumberOfRequest");
        }
      }

      return response;
    },
  },

  getters: {
    // numberOfRequest
    getNumberOfRequest(state) {
      return state.numberOfRequest;
    },

    // maxNumberOfRequest
    getMaxNumberOfRequest(state) {
      return state.maxNumberOfRequest;
    },

    // result
    getResult(state) {
      return state.result;
    },
  },
};

export default initiateDebitOrder;
