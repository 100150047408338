import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import apiEndpoints from "./modules/apiEndpoints";
import auth from "./modules/auth";
import createLoanApplication from "./modules/createLoanApplication";
import document from "./modules/document";
import errorHandler from "./modules/errorHandler";
import generateSettlementQuote from "./modules/generateSettlementQuote";
import generateStatement from "./modules/generateStatement";
import initiateDebitOrder from "./modules/initiateDebitOrder";
import list from "./modules/list";
import login from "./modules/login";
import marketing from "./modules/marketing";
import passwordRecovery from "./modules/passwordRecovery";
import personalData from "./modules/personalData";
import register from "./modules/register";
import registration from "./modules/registration";
import requestDocumentOTP from "./modules/requestDocumentOTP";
import retrieveAttachment from "./modules/retrieveAttachment";
import signDocument from "./modules/signDocument";
import truid from "./modules/truid";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    text: "test",
  },

  mutations: {
    setTest(state, payload) {
      state.text = payload;
    },
  },

  actions: {},

  modules: {
    apiEndpoints,
    auth,
    createLoanApplication,
    document,
    errorHandler,
    generateSettlementQuote,
    generateStatement,
    initiateDebitOrder,
    list,
    login,
    marketing,
    passwordRecovery,
    personalData,
    register,
    registration,
    requestDocumentOTP,
    retrieveAttachment,
    signDocument,
    truid,
  },

  plugins: [createPersistedState()],
});
