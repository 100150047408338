import axios from "../../plugins/axios";

const requestDocumentOTP = {
  namespaced: true,

  state: {
    result: "",
    devOTP: "",
  },

  mutations: {
    // result
    setResult(state, payload) {
      state.result = payload;
    },

    // devOTP
    setDevOTP(state, payload) {
      state.devOTP = payload;
    },

    // resetState
    resetState(state) {
      state.result = "";
      state.devOTP = "";
    },
  },

  actions: {
    // getApiRequestDocumentOTP
    async getApiRequestDocumentOTP({ commit, rootState }) {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      try {
        const res = await axios.post("sign-document-otp", {
          idnumber: rootState.auth.idnumber,
          session_key: rootState.auth.session_key,
          cellular: rootState.auth.user.cellular,
          document_name: "AgreementDocument",
          audit_id: rootState.document.agreementDocumentAuditId,
        });

        if (res.status == 200) {
          const data = res.data;

          response.status = res.status;
          response.data = res.data;

          // result
          if ("result" in data) {
            commit("setResult", data.result);
          }

          // dev_otp
          if ("dev_otp" in data) {
            commit("setDevOTP", data.dev_otp);
          }
        }
      } catch (error) {
        if (error && error.response && error.response.status) {
          response.status = error.response.status;
        }

        if (error && error.response && error.response.data) {
          response.data = error.response.data;
        }
      }

      return response;
    },
  },

  getters: {
    // result
    getResult(state) {
      return state.result;
    },

    // devOTP
    getDevOTP(state) {
      return state.devOTP;
    },
  },
};

export default requestDocumentOTP;
