import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader

import BriefcaseIcon from "@/components/shared/vuetify-icons/BriefcaseIcon";
import UserLIcon from "@/components/shared/vuetify-icons/UserLIcon";
import WalletIcon from "@/components/shared/vuetify-icons/WalletIcon";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#5D9922",
        secondary: "#DB7309",
        tertiary: "#F39100",
        accent: "#BF5900",
        error: "#FF5252",
        error50: "#FFF5F6",
        error500: "#E36050",
        error600: "#BC4636",
        info: "#2196F3",
        success: "#48794F",
        success50: "#E4F7EB",
        success500: "#5B9870",
        success600: "#48794F",
        warning: "#FFC107",
        warning50: "#FEF9E7",
        warning500: "#BC8457",
        warning600: "#A7603C",
        red1: "#F6655A",
        brand200: "#CBE08F",
        brand500: "#97C11F",
        gray50: "#F8F8F8",
        gray100: "#EEEEEE",
        gray200: "#F5F5F5",
        gray300: "#DDDDDD",
        gray400: "#E0E0E0",
        gray500: "#CCCCCC",
        gray600: "#B0B0B0",
        gray700: "#8A8A8A",
        gray800: "#6B6B6B",
        gray900: "#3D3D3D",
        background: "#F5F5F5",
      },
    },
  },
  icons: {
    iconfont: "md",
    values: {
      briefcaseIcon: {
        // name of our custom icon
        component: BriefcaseIcon, // our custom component
      },
      userLIcon: {
        // name of our custom icon
        component: UserLIcon, // our custom component
      },
      walletIcon: {
        // name of our custom icon
        component: WalletIcon, // our custom component
      },
    },
  },
});
