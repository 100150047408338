import axios from "axios";

const baseDomain = process.env.VUE_APP_TRUID_API_BASE_URL;

const baseURL = `${baseDomain}/delivery-api/`;

const truIdDeliveryAxios = axios.create({
  baseURL,
});

truIdDeliveryAxios.defaults.headers = {
  "X-API-KEY": process.env.VUE_APP_TRUID_API_KEY,
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

export default truIdDeliveryAxios;
