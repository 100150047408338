import axios from "../../plugins/axios";

const retrieveAttachment = {
  namespaced: true,

  state: {
    // signed agreement document
    signedAgreementDocumentData: "",
    signedAgreementDocumentFileName: "",
    signedAgreementDocumentResult: "",

    // bank statement document
    bankStatementData: "",
    bankStatementFileName: "",
    bankStatementResult: "",
  },

  mutations: {
    // signedAgreementDocumentData
    setSignedAgreementDocumentData(state, payload) {
      state.signedAgreementDocumentData = payload;
    },

    // signedAgreementDocumentFileName
    setSignedAgreementDocumentFileName(state, payload) {
      state.signedAgreementDocumentFileName = payload;
    },

    // signedAgreementDocumentResult
    setSignedAgreementDocumentResult(state, payload) {
      state.signedAgreementDocumentResult = payload;
    },

    // bankStatementData
    setBankStatementData(state, payload) {
      state.bankStatementData = payload;
    },

    // bankStatementFileName
    setBankStatementFileName(state, payload) {
      state.bankStatementFileName = payload;
    },

    // bankStatementResult
    setBankStatementResult(state, payload) {
      state.bankStatementResult = payload;
    },

    // resetState
    resetState(state) {
      state.signedAgreementDocumentData = "";
      state.signedAgreementDocumentFileName = "";
      state.signedAgreementDocumentResult = "";

      state.bankStatementData = "";
      state.bankStatementFileName = "";
      state.bankStatementResult = "";
    },
  },

  actions: {
    // getApiSignedAgreementDocument
    async getApiSignedAgreementDocument({ commit, rootState }) {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      try {
        // loanId
        var loanId = "";

        // set loanId
        if (rootState.auth.user.open_application_loan_id != "") {
          loanId = rootState.auth.user.open_application_loan_id;
        } else {
          var loans = rootState.auth.user.loans;

          // loans.length
          if (loans.length > 0) {
            var loan = loans[0];

            if ("loan_id" in loan) {
              loanId = loan.loan_id;
            }
          }
        }

        const res = await axios.post("retrieve-attachment", {
          idnumber: rootState.auth.idnumber,
          session_key: rootState.auth.session_key,
          document_name: "SignedAgreementDocument",
          loan_id: loanId,
        });

        if (res.status == 200) {
          const data = res.data;

          response.status = res.status;
          response.data = res.data;

          // result
          if ("result" in data) {
            commit("setSignedAgreementDocumentResult", data.result);
          }

          // filename
          if ("filename" in data) {
            commit("setSignedAgreementDocumentFileName", data.filename);
          }

          // attachment_data
          if ("attachment_data" in data) {
            commit("setSignedAgreementDocumentData", data.attachment_data);
          }
        }
      } catch (error) {
        if (error && error.response && error.response.status) {
          response.status = error.response.status;
        }

        if (error && error.response && error.response.data) {
          response.data = error.response.data;
        }
      }

      return response;
    },

    // getApiBankStatement
    async getApiBankStatement({ commit, rootState }) {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      try {
        const res = await axios.post("retrieve-attachment", {
          idnumber: rootState.auth.idnumber,
          session_key: rootState.auth.session_key,
          document_name: "BankStatement",
          loan_id: rootState.auth.user.open_application_loan_id,
        });

        if (res.status == 200) {
          const data = res.data;

          response.status = res.status;
          response.data = res.data;

          // result
          if ("result" in data) {
            commit("setBankStatementResult", data.result);
          }

          // filename
          if ("filename" in data) {
            commit("setBankStatementFileName", data.filename);
          }

          // attachment_data
          if ("attachment_data" in data) {
            commit("setBankStatementData", data.attachment_data);
          }
        }
      } catch (error) {
        if (error && error.response && error.response.status) {
          response.status = error.response.status;
        }

        if (error && error.response && error.response.data) {
          response.data = error.response.data;
        }
      }

      return response;
    },
  },

  getters: {
    // signedAgreementDocumentData
    getSignedAgreementDocumentData(state) {
      return state.signedAgreementDocumentData;
    },

    // signedAgreementDocumentFileName
    getSignedAgreementDocumentFileName(state) {
      return state.signedAgreementDocumentFileName;
    },

    // signedAgreementDocumentResult
    getSignedAgreementDocumentResult(state) {
      return state.signedAgreementDocumentResult;
    },

    // bankStatementData
    getBankStatementData(state) {
      return state.bankStatementData;
    },

    // bankStatementFileName
    getBankStatementFileName(state) {
      return state.bankStatementFileName;
    },

    // bankStatementResult
    getBankStatementResult(state) {
      return state.bankStatementResult;
    },
  },
};

export default retrieveAttachment;
