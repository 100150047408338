// import Vue from "vue";
// import Vuex from "vuex";

// Vue.use(Vuex);

const register = {
  namespaced: true,
  state: {
    test: "",

    overlay: false,

    overlayformStep1: false,
    validformStep1: false,

    overlayformStep2: false,
    validformStep2: false,

    overlayformStep3: false,
    validformStep3: false,

    stepThree: true,

    step: 1,
    steppers: {
      step1: {
        completed: false,
        circleColor: "brand-gradient",
        pColor: "brand500--text",
      },
      step2: {
        completed: false,
        circleColor: "gray300",
        pColor: "gray800--text",
      },
      step3: {
        completed: false,
        circleColor: "gray300",
        pColor: "gray800--text",
      },
    },

    // Title
    title: "Title",
    titleRules: [
      (v) => !!v || "Should not be title",
      (v) =>
        /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
        "Only english letters, apostrophes and hyphens are allowed",
    ],
    titleHasError: false,
    titleErrorMessage: "name",

    // Name
    name: "Name",
    nameRules: [
      (v) => !!v || "Required",
      (v) =>
        /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
        "Only english letters, apostrophes and hyphens are allowed",
      (v) => v.length <= 50 || "Can not be more than 50 characters",
    ],
    nameHasError: false,
    nameErrorMessage: "name",

    // Surname
    surname: "Surname",
    surnameRules: [
      (v) => !!v || "Required",
      (v) =>
        /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
        "Only english letters, apostrophes and hyphens are allowed",
      (v) => v.length <= 25 || "Can not be more than 25 characters",
    ],
    surnameHasError: false,
    surnameErrorMessage: "surname",

    // Race
    race: "Previously Disadvantaged",
    raceRules: [(v) => !!v || "Required"],
    raceHasError: false,
    raceErrorMessage: "race",

    // ID Number
    idNumber: "7402159582082",
    idNumberRules: [
      (v) => !!v || "Required",
      (v) => /[0-9]{13}/.test(v) || "Id Number must be 13 numbers",
      (v) => v.length == 13 || "Id Number must be 13 numbers",
    ],
    idNumberHasError: false,
    idNumberErrorMessage: "Id Number",

    // Province
    province: "Gauteng",
    provinceRules: [(v) => !!v || "Required"],
    provinceHasError: false,
    provinceErrorMessage: "province",

    // Mobile Phone
    mobilePhone: "012 345 67 89",
    mobilePhoneRules: [
      (v) => !!v || "Required",
      (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
      (v) => v.length == 12 || "Must be 10 numbers",
    ],
    mobilePhoneHasError: false,
    mobilePhoneErrorMessage: "mobile phone",

    // Email
    email: "email@email.com",
    emailRules: [
      (v) => !!v || "Required",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    ],
    emailHasError: false,
    emailErrorMessage: "email",

    // Password
    password: "MySecret123",
    passwordRules: [
      (v) => !!v || "Required",
      (v) => v.length >= 8 || "",
      (v) => v.length <= 30 || "",
      (v) => /^(?=.*?[A-Z])/.test(v) || "",
      (v) => /^(?=.*?[a-z])/.test(v) || "",
      (v) => /^(?=.*?[0-9])/.test(v) || "",
    ],
    passwordHasError: false,
    passwordErrorMessage: "password",

    // Confirm Password
    confirmPassword: "MySecret123",
    confirmPasswordRules: [
      (v) => !!v || "Required",
      (v) => v.length >= 8 || "",
      (v) => v.length <= 30 || "",
      (v) => /^(?=.*?[A-Z])/.test(v) || "",
      (v) => /^(?=.*?[a-z])/.test(v) || "",
      (v) => /^(?=.*?[0-9])/.test(v) || "",
    ],
    confirmPasswordHasError: false,
    confirmPasswordErrorMessage: "confirm password",

    // Agree To Terms And Conditions
    agreeToTermsAndConditions: true,
    agreeToTermsAndConditionsRules: [
      (v) => !!v || "You need to accept the terms of the agreement",
    ],

    // Payment Information
    paymentInformation: {
      // Bank
      bank: "FNB",
      bankRules: [(v) => !!v || "Required"],
      bankHasError: false,
      bankErrorMessage: "bank",

      // Type Of Account
      typeOfAccount: "Savings",
      typeOfAccountRules: [(v) => !!v || "Required"],
      typeOfAccountHasError: false,
      typeOfAccountErrorMessage: "type of account",

      // Bank Account Number
      bankAccountNumber: "123456789",
      bankAccountNumberRules: [
        (v) => !!v || "Required",
        (v) => /^[0-9]+$/.test(v) || "Only 0 to 9 is allowed",
      ],
      bankAccountNumberHasError: false,
      bankAccountNumberErrorMessage: "bank account number",
    },

    // Additional Information
    additionalInformation: {
      // Employment Status
      employmentStatus: "Permanent",
      employmentStatusRules: [(v) => !!v || "Required"],
      employmentStatusHasError: false,
      employmentStatusErrorMessage: "employment status",

      // Next Income Date
      nextIncomeDate: {
        // Day
        day: 25,
        dayRules: [(v) => !!v || "Required"],
        dayHasError: false,
        dayErrorMessage: "day",
      },

      // The Emergency Phone Number
      theEmergencyPhoneNumber: "012 345 67 89",
      theEmergencyPhoneNumberRules: [
        (v) => !!v || "Required",
        (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
        (v) => v.length == 12 || "Must be 10 numbers",
      ],
      theEmergencyPhoneNumberHasError: false,
      theEmergencyPhoneNumberErrorMessage: "the emergency phone number",

      // Years Of Employment
      yearsOfEmployment: "",
      yearsOfEmploymentRules: [(v) => !!v || "Required"],
      yearsOfEmploymentHasError: false,
      yearsOfEmploymentErrorMessage: "years of employment",

      // Month Of Employment
      monthOfEmployment: "",
      monthOfEmploymentRules: [(v) => !!v || "Required"],
      monthOfEmploymentHasError: false,
      monthOfEmploymentErrorMessage: "month of employment",

      // Position
      position: "Undisclosed",
      positionRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length <= 50 || "Can not be more than 50 characters",
      ],
      positionHasError: false,
      positionErrorMessage: "position",

      // Employer Name
      employerName: "Undisclosed",
      employerNameRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],
      employerNameHasError: false,
      employerNameErrorMessage: "employer name",

      // Work Phone
      workPhone: "0115551111",
      workPhoneRules: [(v) => !!v || "Required"],
      workPhoneHasError: false,
      workPhoneErrorMessage: "work phone",

      // Frequency Of Income
      frequencyOfIncome: "monthly",
      frequencyOfIncomeRules: [(v) => !!v || "Required"],
      frequencyOfIncomeHasError: false,
      frequencyOfIncomeErrorMessage: "frequency of income",
    },

    // Expenses
    expenses: {
      // Medical
      medical: "",
      medicalRules: [
        (v) => !!v || "Required",
        (v) => (v && v >= 0) || "Medical Expenses should be R0 or above",
      ],

      // Accomodation
      accomodation: "",
      accomodationRules: [
        (v) => !!v || "Required",
        (v) => (v && v >= 0) || "Accomodation Expenses should be R0 or above",
      ],

      // Groceries And Household
      groceriesAndHousehold: "",
      groceriesAndHouseholdRules: [
        (v) => !!v || "Required",
        (v) =>
          (v && v >= 0) ||
          "Groceries And Household Expenses should be R0 or above",
      ],

      // Transport
      transport: "",
      transportRules: [
        (v) => !!v || "Required",
        (v) => (v && v >= 0) || "Transport Expenses should be R0 or above",
      ],

      // Education
      education: "",
      educationRules: [
        (v) => !!v || "Required",
        (v) => (v && v >= 0) || "Education Expenses should be R0 or above",
      ],

      // Maintenance
      maintenance: "",
      maintenanceRules: [
        (v) => !!v || "Required",
        (v) => (v && v >= 0) || "Maintenance Expenses should be R0 or above",
      ],

      // Water And Electricity
      waterAndElectricity: "",
      waterAndElectricityRules: [
        (v) => !!v || "Required",
        (v) =>
          (v && v >= 0) ||
          "Water And Electricity Expenses should be R0 or above",
      ],

      // Loan Repayment
      loanRepayment: "",
      loanRepaymentRules: [
        (v) => !!v || "Required",
        (v) =>
          (v && v >= 0) || "Loan Repayments Expenses should be R0 or above",
      ],
    },
    expensesHasError: false,
    expensesErrorMessage: "expenses",

    // Income
    income: {
      // Gross Monthly
      grossMonthly: null,
      grossMonthlyRules: [
        (v) => !!v || "Required",
        (v) => (v && v >= 0) || "Gross Monthly Income should be R0 or above",
      ],

      // Net Monthly
      netMonthly: null,
      netMonthlyRules: [
        (v) => !!v || "Required",
        (v) => (v && v >= 0) || "Net Monthly Income should be R0 or above",
      ],

      // Indicative Disposable
      indicativeDisposable: 0,
      indicativeDisposableRules: [(v) => !!v || "Required"],
    },
    incomeHasError: false,
    incomeErrorMessage: "income",

    // list Items 1
    listItems1: [
      {
        text: `Our system have preliminary approved your application.
     You could get up from R300 and up to R8000. Terms and Conditions apply.`,
      },
      {
        text: `You can attach your documents below or send it via email right now`,
      },
    ],

    // list Items 2
    listItems2: [
      {
        text: `Login to your bank profile through the TruID widget (use your data
         from the bank account to which you would like to get money). The following
          options are possible: via SMS confirmation / in your account/by entering
           captcha, etc.`,
      },
      {
        text: `Confirm in the bank profile that you allow using your data for loan
         approval purposes.`,
      },
      {
        text: `Press the "Next" button under the widget to have your loan processed.`,
      },
    ],

    // Show Password
    showPassword: false,

    // Show Confirm Password
    showConfirmPassword: false,

    // Upload Bank Statement
    uploadBankStatement: false,

    // banking details was send
    bankingDetailsWasSend: false,

    // employment details was send
    employmentDetailsWasSend: false,

    // contact details was send
    contactDetailsWasSend: false,
  },

  mutations: {
    setOverlay(state, payload) {
      state.overlay = payload;
    },

    // title
    setTitle(state, payload) {
      state.title = payload;
    },

    // surname
    setSurname(state, payload) {
      state.surname = payload;
    },

    // race
    setRace(state, payload) {
      state.race = payload;
    },

    // province
    setProvince(state, payload) {
      state.province = payload;
    },

    // mobilePhone
    setMobilePhone(state, payload) {
      state.mobilePhone = payload;
    },

    // email
    setEmail(state, payload) {
      state.email = payload;
    },

    // Payment Information
    // bank
    setBank(state, payload) {
      state.paymentInformation.bank = payload;
    },
    // typeOfAccount
    settypeOfAccount(state, payload) {
      state.paymentInformation.typeOfAccount = payload;
    },
    // bankAccountNumber
    setBankAccountNumber(state, payload) {
      state.paymentInformation.bankAccountNumber = payload;
    },

    // Additional Information

    // employmentStatus
    setEmploymentStatus(state, payload) {
      state.additionalInformation.employmentStatus = payload;
    },
    // day
    setDay(state, payload) {
      state.additionalInformation.nextIncomeDate.day = payload;
    },
    // theEmergencyPhoneNumber
    setTheEmergencyPhoneNumber(state, payload) {
      state.additionalInformation.theEmergencyPhoneNumber = payload;
    },
    // yearsOfEmployment
    setYearsOfEmployment(state, payload) {
      state.additionalInformation.yearsOfEmployment = payload;
    },
    // monthOfEmployment
    setMonthOfEmployment(state, payload) {
      state.additionalInformation.monthOfEmployment = payload;
    },
    // position
    setPosition(state, payload) {
      state.additionalInformation.position = payload;
    },
    // employerName
    setEmployerName(state, payload) {
      state.additionalInformation.employerName = payload;
    },
    // workPhone
    setWorkPhone(state, payload) {
      state.additionalInformation.workPhone = payload;
    },
    // frequencyOfIncome
    setFrequencyOfIncome(state, payload) {
      state.additionalInformation.frequencyOfIncome = payload;
    },
  },

  actions: {},

  getters: {},
};

export default register;
