import axios from "../../plugins/axios";

const truid = {
  namespaced: true,

  state: {
    useOnlyTruid: false,
    myCompanyAndBrands: null,
    myCompanyServices: null,
    allSupportedDataProviders: null,
    iframeUrl: "",
    iframeGthrUrl: "",
    currentCollection: "",
    previousCollection: "",
    sessionExpires: "",
    dataToSend: [],
  },

  mutations: {
    // setMyCompanyAndBrands
    setMyCompanyAndBrands(state, payload) {
      state.myCompanyAndBrands = payload;
    },

    // setMyCompanyServices
    setMyCompanyServices(state, payload) {
      state.myCompanyServices = payload;
    },

    // setAllSupportedDataProviders
    setAllSupportedDataProviders(state, payload) {
      state.allSupportedDataProviders = payload;
    },

    // setIframeUrl
    setIframeUrl(state, payload) {
      state.iframeUrl = payload;
    },

    // setIframeGthrUrl
    setIframeGthrUrl(state, payload) {
      state.iframeGthrUrl = payload;
    },

    // setCurrentCollection
    setCurrentCollection(state, payload) {
      state.currentCollection = payload;
    },

    // setPreviousCollection
    setPreviousCollection(state, payload) {
      state.previousCollection = payload;
    },

    // setSessionExpires
    setSessionExpires(state, payload) {
      state.sessionExpires = payload;
    },

    // setUseOnlyTruid
    setUseOnlyTruid(state, payload) {
      state.useOnlyTruid = payload;
    },

    // setDataToSend
    setDataToSend(state, payload) {
      state.dataToSend = payload;
    },

    // pushDataToSend
    pushDataToSend(state, payload) {
      state.dataToSend.push(payload);
    },

    // resetState
    resetState(state) {
      state.myCompanyAndBrands = null;
      state.myCompanyServices = null;
      state.allSupportedDataProviders = null;
      state.iframeUrl = "";
      state.currentCollection = "";
      state.previousCollection = "";
      state.sessionExpires = "";
      state.useOnlyTruid = false;
      state.dataToSend = [];
    },
  },

  actions: {
    // getApiIframeUrl
    async getApiIframeUrl({ commit, rootState }, formData) {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      formData.session_key = rootState.auth.session_key;

      try {
        const res = await axios.post("iframe-url", formData);

        if (res.status == 201) {
          const data = res.data;

          response.status = res.status;
          response.data = res.data;

          // iFrameUrl
          if ("iFrameUrl" in data) {
            commit("setIframeUrl", data.iFrameUrl);
          }

          // currentCollection
          if ("currentCollection" in data) {
            commit("setCurrentCollection", data.currentCollection);
          }
        }
      } catch (error) {
        if (error && error.response && error.response.status) {
          response.status = error.response.status;
        }

        if (error && error.response && error.response.data) {
          response.data = error.response.data;
        }
      }

      return response;
    },

    async getApiIframeGthrUrl({ commit, rootState }, formData) {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      formData.session_key = rootState.auth.session_key;

      try {
        const res = await axios.post("iframegathr-url", formData);

        if (res.status == 201) {
          const data = res.data;

          response.status = res.status;
          response.data = res.data;

          // iFrameUrl
          if ("iFrameUrl" in data) {
            commit("setIframeGthrUrl", data.iFrameUrl);
          }
         
        }
      } catch (error) {
        if (error && error.response && error.response.status) {
          response.status = error.response.status;
        }

        if (error && error.response && error.response.data) {
          response.data = error.response.data;
        }
      }

      return response;
    },

    // getApiCollectionStatuses
    async getApiCollectionStatuses({ rootState }, formData) {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      formData.idNumber = rootState.auth.idnumber;
      formData.session_key = rootState.auth.session_key;
      formData.currentCollection = rootState.truid.currentCollection;

      try {
        const res = await axios.post("collection-statuses", formData);

        if (res.status == 200) {
          response.status = res.status;
          response.data = res.data;
        }
      } catch (error) {
        if (error && error.response && error.response.status) {
          response.status = error.response.status;
        }

        if (error && error.response && error.response.data) {
          response.data = error.response.data;
        }
      }

      return response;
    },

    // getApiProductIndex
    async getApiProductIndex({ rootState }, formData) {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      formData.idNumber = rootState.auth.idnumber;
      formData.session_key = rootState.auth.session_key;
      formData.currentCollection = rootState.truid.currentCollection;

      try {
        const res = await axios.post("product-index", formData);

        if (res.status == 200) {
          response.status = res.status;
          response.data = res.data;
        }
      } catch (error) {
        if (error && error.response && error.response.status) {
          response.status = error.response.status;
        }

        if (error && error.response && error.response.data) {
          response.data = error.response.data;
        }
      }

      return response;
    },

    // getApiTransactionHistory
    async getApiTransactionHistory({ rootState }, formData) {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      formData.idNumber = rootState.auth.idnumber;
      formData.session_key = rootState.auth.session_key;
      formData.currentCollection = rootState.truid.currentCollection;

      try {
        const res = await axios.post("download-transaction-history", formData, {
          responseType: "blob",
        });

        if (res.status == 200) {
          response.status = res.status;
          response.data = res.data;
        }
      } catch (error) {
        if (error && error.response && error.response.status) {
          response.status = error.response.status;
        }

        if (error && error.response && error.response.data) {
          response.data = error.response.data;
        }
      }

      return response;
    },

    // getApiBankStatement
    async getApiBankStatement({ rootState }, formData) {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      formData.idNumber = rootState.auth.idnumber;
      formData.session_key = rootState.auth.session_key;
      formData.currentCollection = rootState.truid.currentCollection;

      try {
        const res = await axios.post("download-bank-statement", formData, {
          responseType: "blob",
        });

        if (res.status == 200) {
          response.status = res.status;
          response.data = res.data;
        }
      } catch (error) {
        if (error && error.response && error.response.status) {
          response.status = error.response.status;
        }

        if (error && error.response && error.response.data) {
          response.data = error.response.data;
        }
      }

      return response;
    },
  },

  getters: {
    // getMyCompanyAndBrands
    getMyCompanyAndBrands(state) {
      return state.myCompanyAndBrands;
    },

    // getMyCompanyServices
    getMyCompanyServices(state) {
      return state.myCompanyServices;
    },

    // getAllSupportedDataProviders
    getAllSupportedDataProviders(state) {
      return state.allSupportedDataProviders;
    },

    // getIframeUrl
    getIframeUrl(state) {
      return state.IframeUrl;
    },

    // setIframeGthrUrl
    getIframeGthrUrl(state) {
      return state.iframeGthrUrl;
    },

    // getCurrentCollection
    getCurrentCollection(state) {
      return state.allSupportedDataProviders;
    },

    // getPreviousCollection
    getPreviousCollection(state) {
      return state.previousCollection;
    },

    // getSessionExpires
    getSessionExpires(state) {
      return state.sessionExpires;
    },

    // getUseOnlyTruid
    getUseOnlyTruid(state) {
      return state.useOnlyTruid;
    },

    // getDataToSend
    getDataToSend(state) {
      return state.dataToSend;
    },
  },
};

export default truid;
