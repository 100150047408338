import axios from "../../plugins/axios";

const generateStatement = {
  namespaced: true,

  state: {
    // statement document
    statementDocumentData: "",
    statementDocumentFileName: "",
    statementDocumentResult: "",
    statementDocumentAuditId: "",
  },

  mutations: {
    // statementDocumentData
    setStatementDocumentData(state, payload) {
      state.statementDocumentData = payload;
    },

    // statementDocumentFileName
    setStatementDocumentFileName(state, payload) {
      state.statementDocumentFileName = payload;
    },

    // statementDocumentResult
    setStatementDocumentResult(state, payload) {
      state.statementDocumentResult = payload;
    },

    // statementDocumentAuditId
    setStatementDocumentAuditId(state, payload) {
      state.statementDocumentAuditId = payload;
    },

    // resetState
    resetState(state) {
      state.statementDocumentData = "";
      state.statementDocumentFileName = "";
      state.statementDocumentResult = "";
      state.statementDocumentAuditId = "";
    },
  },

  actions: {
    // getApiStatementDocument
    async getApiStatementDocument({ commit, rootState }) {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      try {
        const res = await axios.post("generate-statement", {
          idnumber: rootState.auth.idnumber,
          session_key: rootState.auth.session_key,
        });

        if (res.status == 200) {
          const data = res.data;

          response.status = res.status;
          response.data = res.data;

          // result
          if ("result" in data) {
            commit("setStatementDocumentResult", data.result);
          }

          // audit_id
          if ("audit_id" in data) {
            commit("setStatementDocumentAuditId", data.audit_id);
          }

          // filename
          if ("filename" in data) {
            commit("setStatementDocumentFileName", data.filename);
          }

          // document_data
          if ("document_data" in data) {
            commit("setStatementDocumentData", data.document_data);
          }
        }
      } catch (error) {
        if (error && error.response && error.response.status) {
          response.status = error.response.status;
        }

        if (error && error.response && error.response.data) {
          response.data = error.response.data;
        }
      }

      return response;
    },
  },

  getters: {
    // statementDocumentData
    getStatementDocumentData(state) {
      return state.statementDocumentData;
    },

    // statementDocumentFileName
    getStatementDocumentFileName(state) {
      return state.statementDocumentFileName;
    },

    // statementDocumentResult
    getStatementDocumentResult(state) {
      return state.statementDocumentResult;
    },

    // statementDocumentAuditId
    getStatementDocumentAuditId(state) {
      return state.statementDocumentAuditId;
    },
  },
};

export default generateStatement;
