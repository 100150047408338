import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const login = {
  namespaced: true,

  state: {
    overlay: false,
    valid: false,
    mobilePhone: "",
    mobilePhoneRules: [
      (v) => !!v || "Required",
      (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
      (v) => v.length == 12 || "Must be 10 numbers",
    ],
    idnumber: "",
    idnumberRules: [
      (v) => !!v || "Required",
      (v) => /[0-9]{13}/.test(v) || "Id Number must be 13 numbers",
      (v) => v.length == 13 || "Id Number must be 13 numbers",
    ],

    email: "",
    emailRules: [
      (v) => !!v || "Required",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    ],

    password: "",
    passwordRules: [
      (v) => !!v || "Required",
      (v) => v.length >= 8 || "",
      (v) => v.length <= 30 || "",
      (v) => /^(?=.*?[A-Z])/.test(v) || "",
      (v) => /^(?=.*?[a-z])/.test(v) || "",
      (v) => /^(?=.*?[0-9])/.test(v) || "",
    ],

    showPassword: false,
  },

  mutations: {
    setOverlay(state, payload) {
      state.overlay = payload;
    },

    resetState(state) {
      state.idnumber = "";
      state.password = "";
    },
  },

  actions: {},

  getters: {},
};

export default login;
