import axios from "../../plugins/axios";

const document = {
  namespaced: true,
  state: {
    audit_id: "",
    document_data: "",
    filename: "",
    document_name: "",

    // agreement document
    agreementDocumentData: "",
    agreementDocumentFileName: "",
    agreementDocumentAuditId: "",

    // quotation document
    quotationDocumentData: "",
    quotationDocumentFileName: "",
    quotationDocumentAuditId: "",
  },

  mutations: {
    // audit id
    setAuditId(state, payload) {
      state.audit_id = payload;
    },

    // document data
    setDocumentData(state, payload) {
      state.document_data = payload;
    },

    // file name
    setFileName(state, payload) {
      state.filename = payload;
    },

    // document name
    setDocumentName(state, payload) {
      state.document_name = payload;
    },

    // agreementDocumentData
    setAgreementDocumentData(state, payload) {
      state.agreementDocumentData = payload;
    },

    // agreementDocumentFileName
    setAgreementDocumentFileName(state, payload) {
      state.agreementDocumentFileName = payload;
    },

    // agreementDocumentAuditId
    setAgreementDocumentAuditId(state, payload) {
      state.agreementDocumentAuditId = payload;
    },

    // quotationDocumentData
    setQuotationDocumentData(state, payload) {
      state.quotationDocumentData = payload;
    },

    // quotationDocumentFileName
    setQuotationDocumentFileName(state, payload) {
      state.quotationDocumentFileName = payload;
    },

    // quotationDocumentAuditId
    setQuotationDocumentAuditId(state, payload) {
      state.quotationDocumentAuditId = payload;
    },

    // resetState
    resetState(state) {
      state.audit_id = "";
      state.document_data = "";
      state.filename = "";
      state.document_name = "";

      state.agreementDocumentData = "";
      state.agreementDocumentFileName = "";
      state.agreementDocumentAuditId = "";

      state.quotationDocumentData = "";
      state.quotationDocumentFileName = "";
      state.quotationDocumentAuditId = "";
    },
  },

  actions: {
    // getApiAgreementDocument
    async getApiAgreementDocument({ commit, rootState }) {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      try {
        const res = await axios.post("generate-document", {
          idnumber: rootState.auth.idnumber,
          session_key: rootState.auth.session_key,
          document_name: "AgreementDocument",
        });

        if (res.status == 200) {
          const data = res.data;

          response.status = res.status;
          response.data = res.data;

          // audit_id
          if ("audit_id" in data) {
            commit("setAgreementDocumentAuditId", data.audit_id);
          }

          // document_data
          if ("document_data" in data) {
            commit("setAgreementDocumentData", data.document_data);
          }

          // filename
          if ("filename" in data) {
            commit("setAgreementDocumentFileName", data.filename);
          }
        }
      } catch (error) {
        if (error && error.response && error.response.status) {
          response.status = error.response.status;
        }

        if (error && error.response && error.response.data) {
          response.data = error.response.data;
        }
      }

      return response;
    },

    // getApiQuotationDocument
    async getApiQuotationDocument({ commit, rootState }) {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      try {
        const res = await axios.post("generate-document", {
          idnumber: rootState.auth.idnumber,
          session_key: rootState.auth.session_key,
          document_name: "QuotationDocument",
        });

        if (res.status == 200) {
          const data = res.data;

          response.status = res.status;
          response.data = res.data;

          // audit_id
          if ("audit_id" in data) {
            commit("setQuotationDocumentAuditId", data.audit_id);
          }

          // document_data
          if ("document_data" in data) {
            commit("setQuotationDocumentData", data.document_data);
          }

          // filename
          if ("filename" in data) {
            commit("setQuotationDocumentFileName", data.filename);
          }
        }
      } catch (error) {
        if (error && error.response && error.response.status) {
          response.status = error.response.status;
        }

        if (error && error.response && error.response.data) {
          response.data = error.response.data;
        }
      }

      return response;
    },
  },

  getters: {
    // audit id
    getAuditId(state) {
      return state.audit_id;
    },

    // document data
    getDocumentData(state) {
      return state.document_data;
    },

    // file name
    getFileName(state) {
      return state.filename;
    },

    // document_name
    getDocumentName(state) {
      return state.document_name;
    },

    // agreementDocumentData
    getAgreementDocumentData(state) {
      return state.agreementDocumentData;
    },

    // agreementDocumentFileName
    getAgreementDocumentFileName(state) {
      return state.agreementDocumentFileName;
    },

    // agreementDocumentAuditId
    getAgreementDocumentAuditId(state) {
      return state.agreementDocumentAuditId;
    },

    // quotationDocumentData
    getQuotationDocumentData(state) {
      return state.quotationDocumentData;
    },

    // quotationDocumentFileName
    getQuotationDocumentFileName(state) {
      return state.quotationDocumentFileName;
    },

    // quotationDocumentAuditId
    getQuotationDocumentAuditId(state) {
      return state.quotationDocumentAuditId;
    },
  },
};

export default document;
