import moment from "moment/src/moment";

const marketing = {
  namespaced: true,

  state: {
    lead_id: "",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_content: "",
    utm_term: "",
    webmaster_id: "",
    clickid: "",
    gclid: "",
    mindbox_click_id: "",
    session_expires: "",
  },

  mutations: {
    // setLeadId
    setLeadId(state, payload) {
      state.lead_id = payload;
    },

    // setUTMSource
    setUTMSource(state, payload) {
      state.utm_source = payload;
    },

    // setUTMMedium
    setUTMMedium(state, payload) {
      state.utm_medium = payload;
    },

    // setUTMCampaign
    setUTMCampaign(state, payload) {
      state.utm_campaign = payload;
    },

    // setUTMContent
    setUTMContent(state, payload) {
      state.utm_content = payload;
    },

    // setUTMTerm
    setUTMTerm(state, payload) {
      state.utm_term = payload;
    },

    // setUTMWebmasterId
    setUTMWebmasterId(state, payload) {
      state.webmaster_id = payload;
    },

    // setClickId
    setClickId(state, payload) {
      state.clickid = payload;
    },

    // setGClid
    setGClid(state, payload) {
      state.gclid = payload;
    },

    // setMindboxClickId
    setMindboxClickId(state, payload) {
      state.mindbox_click_id = payload;
    },

    // setSessionExpires
    setSessionExpires(state) {
      state.session_expires = moment().add(30, "days");
    },

    // resetState
    resetState(state) {
      state.lead_id = "";
      state.utm_source = "";
      state.utm_medium = "";
      state.utm_campaign = "";
      state.utm_content = "";
      state.utm_term = "";
      state.webmaster_id = "";
      state.clickid = "";
      state.gclid = "";
      state.mindbox_click_id = "";

      state.session_expires = "";
    },
  },

  actions: {},

  getters: {
    // getLeadId
    getLeadId(state) {
      return state.lead_id;
    },

    // getUTMSource
    getUTMSource(state) {
      return state.utm_source;
    },

    // getUTMMedium
    getUTMMedium(state) {
      return state.utm_medium;
    },

    // getUTMCampaign
    getUTMCampaign(state) {
      return state.utm_campaign;
    },

    // getUTMContent
    getUTMContent(state) {
      return state.utm_content;
    },

    // getUTMTerm
    getUTMTerm(state) {
      return state.utm_term;
    },

    // getUTMWebmasterId
    getUTMWebmasterId(state) {
      return state.webmaster_id;
    },

    // getClickId
    getClickId(state) {
      return state.clickid;
    },

    // getGClid
    getGClid(state) {
      return state.gclid;
    },

    // getMindboxClickId
    getMindboxClickId(state) {
      return state.mindbox_click_id;
    },

    // getSessionExpires
    getSessionExpires(state) {
      return state.session_expires;
    },

    // checkSessionExpired
    checkSessionExpired(state) {
      if (state.session_expires == "") return Boolean(false);

      return Boolean(moment().isAfter(state.session_expires));
    },

    // checkForMarketing
    checkForMarketing(state) {
      return (
        state.lead_id != "" ||
        state.utm_source != "" ||
        state.utm_medium != "" ||
        state.utm_campaign != "" ||
        state.utm_content != "" ||
        state.utm_term != "" ||
        state.webmaster_id != "" ||
        state.clickid != "" ||
        state.gclid != "" ||
        state.mindbox_click_id != ""
      );
    },
  },
};

export default marketing;
