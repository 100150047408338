const personalData = {
  namespaced: true,

  state: {
    showMessage: false,

    personalDetailsTabHasSuccess: false,
    bankingAndEmploymentTabHasSuccess: false,
    nextOfKinTabHasSuccess: false,
    passwordTabHasSuccess: false,
  },

  mutations: {
    // showMessage
    setShowMessage(state, payload) {
      state.showMessage = payload;
    },

    // personalDetailsTabHasSuccess
    setPersonalDetailsTabHasSuccess(state, payload) {
      state.personalDetailsTabHasSuccess = payload;
    },

    // bankingAndEmploymentTabHasSuccess
    setBankingAndEmploymentTabHasSuccess(state, payload) {
      state.bankingAndEmploymentTabHasSuccess = payload;
    },

    // nextOfKinTabHasSuccess
    setNextOfKinTabHasSuccess(state, payload) {
      state.nextOfKinTabHasSuccess = payload;
    },

    // passwordTabHasSuccess
    setPasswordTabHasSuccess(state, payload) {
      state.passwordTabHasSuccess = payload;
    },

    // resetState
    resetState(state) {
      state.showMessage = false;

      state.personalDetailsTabHasSuccess = false;
      state.bankingAndEmploymentTabHasSuccess = false;
      state.nextOfKinTabHasSuccess = false;
      state.passwordTabHasSuccess = false;
    },
  },

  actions: {
    //
  },

  getters: {
    //
  },
};

export default personalData;
