import axios from "../../plugins/axios";

const generateSettlementQuote = {
  namespaced: true,

  state: {
    // settlement quote document
    settlementQuoteDocumentData: "",
    settlementQuoteDocumentFileName: "",
    settlementQuoteDocumentResult: "",
  },

  mutations: {
    // settlementQuoteDocumentData
    setSettlementQuoteDocumentData(state, payload) {
      state.settlementQuoteDocumentData = payload;
    },

    // settlementQuoteDocumentFileName
    setSettlementQuoteDocumentFileName(state, payload) {
      state.settlementQuoteDocumentFileName = payload;
    },

    // settlementQuoteDocumentResult
    setSettlementQuoteDocumentResult(state, payload) {
      state.settlementQuoteDocumentResult = payload;
    },

    // resetState
    resetState(state) {
      state.settlementQuoteDocumentData = "";
      state.settlementQuoteDocumentFileName = "";
      state.settlementQuoteDocumentResult = "";
    },
  },

  actions: {
    // getApiSettlementQuoteDocument
    async getApiSettlementQuoteDocument({ commit, rootState }) {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      try {
        const res = await axios.post("generate-settlement-quote", {
          idnumber: rootState.auth.idnumber,
          session_key: rootState.auth.session_key,
        });

        if (res.status == 200) {
          const data = res.data;

          response.status = res.status;
          response.data = res.data;

          // result
          if ("result" in data) {
            commit("setSettlementQuoteDocumentResult", data.result);
          }

          // filename
          if ("filename" in data) {
            commit("setSettlementQuoteDocumentFileName", data.filename);
          }

          // document_data
          if ("document_data" in data) {
            commit("setSettlementQuoteDocumentData", data.document_data);
          }
        }
      } catch (error) {
        if (error && error.response && error.response.status) {
          response.status = error.response.status;
        }

        if (error && error.response && error.response.data) {
          response.data = error.response.data;
        }
      }

      return response;
    },
  },

  getters: {
    // settlementQuoteDocumentData
    getSettlementQuoteDocumentData(state) {
      return state.settlementQuoteDocumentData;
    },

    // settlementQuoteDocumentFileName
    getSettlementQuoteDocumentFileName(state) {
      return state.settlementQuoteDocumentFileName;
    },

    // settlementQuoteDocumentResult
    getSettlementQuoteDocumentResult(state) {
      return state.settlementQuoteDocumentResult;
    },
  },
};

export default generateSettlementQuote;
