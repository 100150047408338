import axios from "../../plugins/axios";
import router from "../../router";

const errorHandler = {
  namespaced: true,

  state: {
    errorCount: 0,
    maxErrorCount: 3,
    justGotErrors: false,
    redirect: "",
    ping: "",
  },

  mutations: {
    // setErrorCount
    setErrorCount(state, payload) {
      state.errorCount = payload;
    },

    // setIncrementErrorCount
    setIncrementErrorCount(state) {
      state.errorCount += 1;
    },

    // setJustGotErrors
    setJustGotErrors(state, payload) {
      state.justGotErrors = payload;
    },

    // setRedirect
    setRedirect(state, payload) {
      state.redirect = payload;
    },

    // resetState
    resetState(state) {
      state.errorCount = 0;
      state.justGotErrors = false;
      state.redirect = "";
    },
  },

  actions: {
    // checkErrorCount
    async checkErrorCount({ rootState, commit }) {
      // setIncrementErrorCount
      commit("setIncrementErrorCount", window.location.pathname);

      if (
        rootState.errorHandler.errorCount >=
        rootState.errorHandler.maxErrorCount
      ) {
        // setRedirect
        commit("setRedirect", window.location.pathname);

        // setJustGotErrors
        commit("setJustGotErrors", true);

        // redirect
        router.push({
          name: "500Page",
        });
      }
    },

    // pingApi
    async pingApi() {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      try {
        const res = await axios.post("ping");

        if (res.status == 200) {
          response.status = res.status;
          response.data = res.data;
        }
      } catch (error) {
        if (error && error.response && error.response.status) {
          response.status = error.response.status;
        }

        if (error && error.response && error.response.data) {
          response.data = error.response.data;
        }
      }

      return response;
    },
  },

  getters: {},
};

export default errorHandler;
