<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "App",

  data() {
    return { waitTimeMilliseconds: 1000 };
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  created() {
    this.openLiveagent();

    window.setInterval(() => {
      if (this.auth.session_expires != "") {
        var now = this.moment();
        var sessionExpires = this.moment(this.auth.session_expires);

        const isAfter = this.moment(now).isAfter(sessionExpires);

        if (isAfter) {
          this.logOutUser();

          // console.log("Logged Out");
        } else {
          // console.log("Current Time", now.format("Do MMMM YYYY, HH:mm:ss"));
          // console.log(
          //   "sessionExpires",
          //   sessionExpires.format("Do MMMM YYYY, HH:mm:ss")
          // );
        }

        this.waitTimeMilliseconds = 10 * 1000;
      } else {
        this.waitTimeMilliseconds = 1 * 60 * 1000;
        // console.log("Not Logged In!");
      }
    }, this.waitTimeMilliseconds);
  },

  methods: {
    ...mapActions({
      logOutUser: "auth/logOutUser",
    }),

    // openLiveagent
    openLiveagent() {
      "use strict";

      setTimeout(() => {
        (function (d, src, c) {
          var t = d.scripts[d.scripts.length - 1],
            s = d.createElement("script");
          s.id = "la_x2s6df8d";
          s.async = true;
          s.src = src;
          s.onload = s.onreadystatechange = function () {
            var rs = this.readyState;
            if (rs && rs != "complete" && rs != "loaded") {
              return;
            }
            c(this);
          };
          t.parentElement.insertBefore(s, t.nextSibling);
        })(
          document,
          "https://lime24sa.ladesk.com/scripts/track.js",
          function (e) {
            // eslint-disable-next-line no-undef
            LiveAgent.createButton("6c0f36eb", e);
          }
        );
      }, 0);
    },
  },
};
</script>
