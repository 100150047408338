import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueSlider from "vue-slider-component";

import "@/assets/scss/_vue_slider_component.scss";
import store from "./store";

import truIdAxios from "./plugins/truIdAxios";
import truIdDeliveryAxios from "./plugins/truIdDeliveryAxios";
import instance from "./plugins/axios";

import VueAxios from "vue-axios";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import OwlCarousel from "owl-carousel-vuejs";
import VueFriendlyIframe from "vue-friendly-iframe";

import VueIframe from "vue-iframes";

import * as Sentry from "@sentry/vue";

Vue.use(VueIframe);
Vue.use(VueFriendlyIframe);

import VueCountdown from "@chenfengyuan/vue-countdown";

Vue.component(VueCountdown.name, VueCountdown);

import moment from "moment/src/moment";

Vue.prototype.moment = moment;

const fileDownload = require("js-file-download");
Vue.prototype.fileDownload = fileDownload;

// eslint-disable-next-line
Vue.component("carousel", OwlCarousel);

import VueCookies from "vue-cookies";

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expires: "30d" });

import helpers from "./plugins/helpers";

const plugin = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  },
};

Vue.use(plugin);
const options = {
  confirmButtonColor: "#97C11F",
  cancelButtonColor: "#ff7674",
};

Vue.component("VueSlider", VueSlider);

Vue.use(VueAxios, { axios: instance, truIdAxios, truIdDeliveryAxios });

Vue.use(VueSweetalert2, options);

import VueGtm from "vue-gtm";

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID, // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  queryParams: {
    // Add url query string when load gtm.js with GTM ID (optional)
    // gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
    // gtm_preview: "env-4",
    // gtm_cookies_win: "x",
  },
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

// import VueMeta from "vue-meta";

// Vue.use(VueMeta, {
//   // optional pluginOptions
//   refreshOnceOnNavigation: true,
// });

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/lime24\.co\.za/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
