const registration = {
  namespaced: true,
  state: {
    currentState: "step1",
  },

  mutations: {
    setCurrentState(state, payload) {
      state.currentState = payload;
    },

    // resetState
    resetState(state) {
      state.currentState = "step1";
    },
  },

  actions: {},

  getters: {
    getCurrentState(state) {
      return state.currentState;
    },
  },
};

export default registration;
