import store from "@/store";
import Swal from "sweetalert2";

function downloadBase64PDF(attachmentData, fileName) {
  const linkSource = `data:application/pdf;base64,${attachmentData}`;
  const downloadLink = document.createElement("a");

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

// formatMobileNumber
function formatMobileNumber(cellular) {
  // Check if cellular is defined and is a string
  if (typeof cellular !== "string") {
    return "";
  }

  let newCellular = "";

  if (cellular.substring(0, 2) === "+27" || cellular.includes("+27")) {
    newCellular =
      "0" +
      cellular.substring(3, 5) +
      " " +
      cellular.substring(5, 8) +
      " " +
      cellular.substring(8, 12);
  } else {
    return cellular;
  }

  return newCellular;
}

// formatInputMobileNumber
function formatInputMobileNumber(entry = "") {
  // Add leading zero
  if (entry.length > 0) {
    if (entry[0] != "0") {
      entry = "0" + entry;
    }
  }

  const entryStripped = entry.replace(/\D+/g, "").replace(/^1/, "");
  const match = entryStripped.match(/([^\d]*\d[^\d]*){1,10}$/);

  // If match not found, return the original entry
  if (!match) {
    return entryStripped;
  }

  const matchedString = match[0];
  const part1 =
    matchedString.length > 2
      ? `${matchedString.substring(0, 3)}`
      : matchedString;
  const part2 =
    matchedString.length > 3 ? ` ${matchedString.substring(3, 6)}` : "";
  const part3 =
    matchedString.length > 6 ? ` ${matchedString.substring(6, 10)}` : "";

  return `${part1}${part2}${part3}`;
}

// formatTheHeaderUserFullname
function formatTheHeaderUserFullname(screenSize, fullName) {
  if (screenSize == "small") {
    return fullName.length > 8 ? `${fullName.substring(0, 8)}...` : fullName;
  } else if (screenSize == "medium") {
    return fullName.length > 12 ? `${fullName.substring(0, 12)}...` : fullName;
  } else if (screenSize == "large") {
    return fullName.length > 16 ? `${fullName.substring(0, 16)}...` : fullName;
  } else if (screenSize == "sidebar") {
    return fullName.length > 16 ? `${fullName.substring(0, 16)}...` : fullName;
  } else if (screenSize == "other") {
    return fullName.length > 25 ? `${fullName.substring(0, 25)}...` : fullName;
  } else {
    return fullName;
  }
}

// blobTobase64
async function blobTobase64(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () =>
      resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
    reader.readAsDataURL(blob);
  });
}

// resetErrorHandlerState
async function resetErrorHandlerState() {
  store.commit("errorHandler/resetState");
}

// checkErrorCount
async function checkErrorCount() {
  store.dispatch("errorHandler/checkErrorCount");
}

// handleError
function handleError(
  status = 500,
  data = {
    message: "Something went wrong please try again!",
  }
) {
  console.log("status", status);
  console.log("message", data.message);

  if (status == 500) {
    Swal.fire({
      title: "Internal Server Error!",
      text: "Please try again!",
      icon: "error",
      // confirmButtonText: "Ok",
    });
  } else if (status == 503) {
    Swal.fire({
      title: "Service Temporarily Unavailable!",
      text: "Please try again!",
      icon: "error",
      // confirmButtonText: "Ok",
    });
  } else {
    Swal.fire({
      title: "Internal Server Error!",
      text: "Please try again!",
      icon: "error",
      // confirmButtonText: "Ok",
    });
  }
}

export default {
  downloadBase64PDF,
  formatMobileNumber,
  formatInputMobileNumber,
  formatTheHeaderUserFullname,
  blobTobase64,
  checkErrorCount,
  resetErrorHandlerState,
  handleError,
};
