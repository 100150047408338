import moment from "moment/src/moment";

const list = {
  namespaced: true,

  state: {
    lists: {
      days: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],

      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],

      maritalStatuses: ["Single", "Married", "Widowed", "Divorced"],

      sexes: ["Male", "Female"],

      races: [
        {
          description: "Black",
        },
        {
          description: "Coloured",
        },
        {
          description: "White",
        },
        {
          description: "Indian",
        },
        {
          description: "Other",
        },
      ],

      //From Api

      titles: [
        {
          description: "Mr",
        },
        {
          description: "Ms",
        },
      ],

      provinces: [
        {
          description: "Eastern Cape",
        },
        {
          description: "Free State",
        },
        {
          description: "Gauteng",
        },
        {
          description: "KZN",
        },
        {
          description: "Limpopo",
        },
        {
          description: "Mpumalanga",
        },
        {
          description: "Northern Cape",
        },
        {
          description: "North West",
        },
        {
          description: "Western Cape",
        },
      ],

      attachment_types: [
        {
          description: "Identity document",
        },
        {
          description: "Signed agreement",
        },
        {
          description: "Bank statement",
        },
        {
          description: "Employment confirmation",
        },
        {
          description: "TruID data",
        },
        {
          description: "OCR data",
        },
        {
          description: "Alec data",
        },
        {
          description: "Jerry data",
        },
      ],

      banks: [
        {
          name: "FNB",
          universal_code: "250655",
          allows_debit_orders: 1,
        },
        {
          name: "Standard Bank",
          universal_code: "051001",
          allows_debit_orders: 1,
        },
        {
          name: "ABSA",
          universal_code: "632005",
          allows_debit_orders: 1,
        },
        {
          name: "Nedbank",
          universal_code: "196005",
          allows_debit_orders: 1,
        },
        {
          name: "Capitec bank",
          universal_code: "470010",
          allows_debit_orders: 1,
        },
        {
          name: "Investec",
          universal_code: "580105",
          allows_debit_orders: 1,
        },
        {
          name: "Bidvest Bank",
          universal_code: "462005",
          allows_debit_orders: 1,
        },
        {
          name: "Finbond",
          universal_code: "589000",
          allows_debit_orders: 1,
        },
        {
          name: "U-Bank",
          universal_code: "431010",
          allows_debit_orders: 1,
        },
        {
          name: "NBS Bank",
          universal_code: "",
          allows_debit_orders: 1,
        },
        {
          name: "Saambou bank",
          universal_code: " ",
          allows_debit_orders: 1,
        },
        {
          name: "Mercantile bank",
          universal_code: "450105",
          allows_debit_orders: 1,
        },
        {
          name: "Access Bank (Grobank)",
          universal_code: "",
          allows_debit_orders: 0,
        },
        {
          name: "African Bank",
          universal_code: "430000",
          allows_debit_orders: 0,
        },
        {
          name: "Discovery Bank",
          universal_code: "679000",
          allows_debit_orders: 0,
        },
        {
          name: "TymeBank",
          universal_code: "678910",
          allows_debit_orders: 0,
        },
        {
          name: "Grindrod Bank",
          universal_code: "223 626",
          allows_debit_orders: 1,
        },
        {
          name: "Other",
          universal_code: "000 000",
          allows_debit_orders: 0,
        },
      ],

      bank_account_types: [
        {
          description: "Cheque/Current",
        },
        {
          description: "Savings",
        },
        {
          description: "Transmission",
        },
      ],

      employment_types: [
        {
          description: "Full-time",
        },
        {
          description: "Temporary",
        },
        {
          description: "Contract",
        },
        {
          description: "Part-time",
        },
        {
          description: "Self-employed",
        },
        {
          description: "Student",
        },
        {
          description: "Homemaker",
        },
        {
          description: "Unemployed",
        },
      ],

      salary_frequency: [
        {
          description: "weekly",
        },
        {
          description: "fortnightly",
        },
        {
          description: "monthly",
        },
      ],

      document_names: [
        {
          description: "AgreementDocument",
        },
        {
          description: "QuotationDocument",
        },
        {
          description: "BankStatement",
        },
      ],

      relationships_types: [
        {
          description: "Aunt",
        },
        {
          description: "Uncle",
        },
        {
          description: "Mother",
        },
        {
          description: "Father",
        },
        {
          description: "Grandfather",
        },
        {
          description: "Grandmother",
        },
      ],

      marital_statuses: [
        {
          description: "Married",
        },
        {
          description: "Single",
        },
        {
          description: "Widowed",
        },
        {
          description: "Divorced",
        },
        {
          description: "Separated",
        },
        {
          description: "Living together",
        },
        {
          description: "Other",
        },
        {
          description: "Unknown",
        },
      ],
    },

    session_expires: null,
  },

  mutations: {
    setDefaultState(state) {
      state.lists = {
        days: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
        ],

        months: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],

        maritalStatuses: ["Single", "Married", "Widowed", "Divorced"],

        sexes: ["Male", "Female"],

        races: [
          {
            description: "Black",
          },
          {
            description: "Coloured",
          },
          {
            description: "White",
          },
          {
            description: "Indian",
          },
          {
            description: "Other",
          },
        ],

        //From Api

        titles: [
          {
            description: "Mr",
          },
          {
            description: "Ms",
          },
        ],

        provinces: [
          {
            description: "Eastern Cape",
          },
          {
            description: "Free State",
          },
          {
            description: "Gauteng",
          },
          {
            description: "KZN",
          },
          {
            description: "Limpopo",
          },
          {
            description: "Mpumalanga",
          },
          {
            description: "Northern Cape",
          },
          {
            description: "North West",
          },
          {
            description: "Western Cape",
          },
        ],

        attachment_types: [
          {
            description: "Identity document",
          },
          {
            description: "Signed agreement",
          },
          {
            description: "Bank statement",
          },
          {
            description: "Employment confirmation",
          },
          {
            description: "TruID data",
          },
          {
            description: "OCR data",
          },
          {
            description: "Alec data",
          },
          {
            description: "Jerry data",
          },
        ],

        banks: [
          {
            name: "FNB",
            universal_code: "250655",
            allows_debit_orders: 1,
          },
          {
            name: "Standard Bank",
            universal_code: "051001",
            allows_debit_orders: 1,
          },
          {
            name: "ABSA",
            universal_code: "632005",
            allows_debit_orders: 1,
          },
          {
            name: "Nedbank",
            universal_code: "198765",
            allows_debit_orders: 1,
          },
          {
            name: "Capitec bank",
            universal_code: "470010",
            allows_debit_orders: 1,
          },
          {
            name: "Finbond Net",
            universal_code: "591000",
            allows_debit_orders: 1,
          },
          {
            name: "African Bank",
            universal_code: "430000",
            allows_debit_orders: 0,
          },
        ],

        bank_account_types: [
          {
            description: "Cheque/Current",
          },
          {
            description: "Savings",
          },
          {
            description: "Transmission",
          },
        ],

        employment_types: [
          {
            description: "Full-time",
          },
          {
            description: "Temporary",
          },
          {
            description: "Contract",
          },
          {
            description: "Part-time",
          },
          {
            description: "Self-employed",
          },
          {
            description: "Student",
          },
          {
            description: "Homemaker",
          },
          {
            description: "Unemployed",
          },
        ],

        salary_frequency: [
          {
            description: "weekly",
          },
          {
            description: "fortnightly",
          },
          {
            description: "monthly",
          },
        ],

        document_names: [
          {
            description: "AgreementDocument",
          },
          {
            description: "QuotationDocument",
          },
          {
            description: "BankStatement",
          },
        ],

        relationships_types: [
          {
            description: "Other",
          },
          {
            description: "Spouse",
          },
          {
            description: "Mother",
          },
          {
            description: "Father",
          },
          {
            description: "Brother",
          },
          {
            description: "Sister",
          },
          {
            description: "Friend",
          },
        ],

        marital_statuses: [
          {
            description: "Married",
          },
          {
            description: "Single",
          },
          {
            description: "Widowed",
          },
          {
            description: "Divorced",
          },
          {
            description: "Separated",
          },
          {
            description: "Living together",
          },
          {
            description: "Other",
          },
          {
            description: "Unknown",
          },
        ],
      };
    },

    setLists(state, payload) {
      // titles
      if ("titles" in payload) {
        state.lists.titles = payload.titles;
      }

      // provinces
      if ("provinces" in payload) {
        state.lists.provinces = payload.provinces;
      }

      // attachment_types
      if ("attachment_types" in payload) {
        state.lists.attachment_types = payload.attachment_types;
      }

      // banks
      // if ("banks" in payload) {
      //   state.lists.banks = payload.banks;
      // }

      state.lists.banks = [
        {
          name: "FNB",
          universal_code: "250655",
          allows_debit_orders: 1,
        },
        {
          name: "Standard Bank",
          universal_code: "051001",
          allows_debit_orders: 1,
        },
        {
          name: "ABSA",
          universal_code: "632005",
          allows_debit_orders: 1,
        },
        {
          name: "Nedbank",
          universal_code: "198765",
          allows_debit_orders: 1,
        },
        {
          name: "Capitec bank",
          universal_code: "470010",
          allows_debit_orders: 1,
        },
        {
          name: "Finbond Net",
          universal_code: "591000",
          allows_debit_orders: 1,
        },
        {
          name: "African Bank",
          universal_code: "430000",
          allows_debit_orders: 0,
        },
      ];

      // bank_account_types
      if ("bank_account_types" in payload) {
        state.lists.bank_account_types = payload.bank_account_types;
      }

      // employment_types
      if ("employment_types" in payload) {
        state.lists.employment_types = payload.employment_types;
      }

      // salary_frequency
      if ("salary_frequency" in payload) {
        state.lists.salary_frequency = payload.salary_frequency;
      }

      // document_names
      if ("document_names" in payload) {
        state.lists.document_names = payload.document_names;
      }

      // relationships_types
      if ("relationships_types" in payload) {
        state.lists.relationships_types = payload.relationships_types;
      }

      // marital_statuses
      if ("marital_statuses" in payload) {
        state.lists.marital_statuses = payload.marital_statuses;
      }
    },

    setDaysList(state, payload) {
      state.lists.days = payload;
    },

    setRacesList(state, payload) {
      state.lists.races = payload;
    },

    setTitlesList(state, payload) {
      state.lists.titles = payload;
    },

    setProvincesList(state, payload) {
      state.lists.provinces = payload;
    },

    setAttachmentTypesList(state, payload) {
      state.lists.attachment_types = payload;
    },

    setBanksList(state, payload) {
      state.lists.banks = payload;
    },

    setBankAccountTypesList(state, payload) {
      state.lists.bank_account_types = payload;
    },

    setEmploymentTypesList(state, payload) {
      state.lists.employment_types = payload;
    },

    setSalaryFrequencyList(state, payload) {
      state.lists.salary_frequency = payload;
    },

    setDocumentNamesList(state, payload) {
      state.lists.document_names = payload;
    },

    setRelationshipTypesList(state, payload) {
      state.lists.relationships_types = payload;
    },

    setMaritalStatusTypesList(state, payload) {
      state.lists.marital_statuses = payload;
    },

    // setSessionExpires
    setSessionExpires(state, payload) {
      state.session_expires = payload;
    },

    // setSessionExpiresInAnHour
    setSessionExpiresInAnHour(state) {
      state.session_expires = moment().add(5, "minutes");
    },

    // resetState
    resetState(state) {
      // console.log("dsfvwserfverv");

      state.lists.days = [];
      state.lists.months = [];
      state.lists.maritalStatuses = [];
      state.lists.sexes = [];
      state.lists.races = [];

      state.lists.titles = [];
      state.lists.provinces = [];
      state.lists.attachment_types = [];
      state.lists.banks = [];
      state.lists.bank_account_types = [];
      state.lists.employment_types = [];
      state.lists.salary_frequency = [];
      state.lists.document_names = [];
      state.lists.relationships_types = [];
      state.lists.marital_statuses = [];
    },
  },

  actions: {
    /* async getApiLists({ commit }) {
      try {
        const res = await axios.post("lists");

        if (res.status == 200) {
          const data = res.data;

          // titles
          if ("titles" in data) {
            // setTitlesList
            commit("setTitlesList", data.titles);
          }

          // provinces
          if ("provinces" in data) {
            // setProvincesList
            commit("setProvincesList", data.provinces);
          }

          // attachment_types
          if ("attachment_types" in data) {
            // setAttachmentTypesList
            commit("setAttachmentTypesList", data.attachment_types);
          }

          // banks
          if ("banks" in data) {
            // setBanksList
            commit("setBanksList", data.banks);
          }

          // bank_account_types
          if ("bank_account_types" in data) {
            // setBankAccountTypesList
            commit("setBankAccountTypesList", data.bank_account_types);
          }

          // employment_types
          if ("employment_types" in data) {
            // setEmploymentTypesList
            commit("setEmploymentTypesList", data.employment_types);
          }

          // document_names
          if ("document_names" in data) {
            // setDocumentNamesList
            commit("setDocumentNamesList", data.document_names);
          }
        }
      } catch (error) {
        //
      }
    }, */
  },

  getters: {
    getLists(state) {
      return state.lists;
    },

    getSessionExpires(state) {
      return state.session_expires;
    },

    getDaysList(state) {
      return state.lists.days;
    },

    getRacesList(state) {
      const races = [];

      state.lists.races.forEach((race) => {
        races.push(race.description);
      });

      return races;
    },

    getTitlesList(state) {
      const titles = [];

      state.lists.titles.forEach((title) => {
        titles.push(title.description);
      });

      return titles.sort();
    },

    getProvincesList(state) {
      const provinces = [];

      state.lists.provinces.forEach((province) => {
        provinces.push(province.description);
      });

      return provinces.sort();
    },

    getAttachmentTypesList(state) {
      const attachment_types = [];

      state.lists.attachment_types.forEach((attachment_type) => {
        attachment_types.push(attachment_type.description);
      });

      return attachment_types.sort();
    },

    getBanksList(state) {
      var banks = [];

      state.lists.banks = [
        {
          name: "FNB",
          universal_code: "250655",
          allows_debit_orders: 1,
        },
        {
          name: "Standard Bank",
          universal_code: "051001",
          allows_debit_orders: 1,
        },
        {
          name: "ABSA",
          universal_code: "632005",
          allows_debit_orders: 1,
        },
        {
          name: "Nedbank",
          universal_code: "198765",
          allows_debit_orders: 1,
        },
        {
          name: "Capitec bank",
          universal_code: "470010",
          allows_debit_orders: 1,
        },
        {
          name: "Finbond Net",
          universal_code: "591000",
          allows_debit_orders: 1,
        },
        {
          name: "African Bank",
          universal_code: "430000",
          allows_debit_orders: 0,
        },
      ];

      state.lists.banks.forEach((bank) => {
        banks.push(bank.name);
      });

      banks = banks.sort();

      var index = banks.findIndex((bank) => bank.name == "Other");
      // here you can check specific property for an object whether it exist in your array or not

      index === -1 ? banks.push("Other") : console.log("");

      return banks;
    },

    getBankAccountTypesList(state) {
      const bank_account_types = [];

      state.lists.bank_account_types.forEach((bank_account_type) => {
        bank_account_types.push(bank_account_type.description);
      });

      return bank_account_types.sort();
    },

    getEmploymentTypesList(state) {
      var employment_types = [];

      state.lists.employment_types.forEach((employment_type) => {
        employment_types.push(employment_type.description);
      });

      employment_types = employment_types.sort();

      var index = employment_types.findIndex(
        (relationships_type) => relationships_type == "Other"
      );

      // here you can check specific property for an object whether it exist in your array or not
      if (index !== -1) {
        employment_types.splice(index, 1);
      }

      employment_types.push("Other");

      return employment_types;
    },

    getSalaryFrequencyList(state) {
      const frequencies = [];

      state.lists.salary_frequency.forEach((salary_frequency) => {
        frequencies.push(salary_frequency.description);
      });

      return frequencies.sort();
    },

    getDocumentNamesList(state) {
      const document_names = [];

      state.lists.document_names.forEach((document_name) => {
        document_names.push(document_name.description);
      });

      return document_names.sort();
    },

    getRelationshipTypesList(state) {
      var relationships_types = [];

      state.lists.relationships_types.forEach((relationships_type) => {
        relationships_types.push(relationships_type.description);
      });

      relationships_types = relationships_types.sort();

      var index = relationships_types.findIndex(
        (relationships_type) => relationships_type == "Other"
      );

      // here you can check specific property for an object whether it exist in your array or not
      if (index !== -1) {
        relationships_types.splice(index, 1);
      }

      relationships_types.push("Other");

      // return relationships_types.sort();
      return relationships_types;
    },

    getMaritalStatusTypesList(state) {
      var marital_statuses = [];

      state.lists.marital_statuses.forEach((marital_status) => {
        marital_statuses.push(marital_status.description);
      });

      marital_statuses = marital_statuses.sort();

      var index = marital_statuses.findIndex(
        (marital_status) => marital_status == "Other"
      );

      // here you can check specific property for an object whether it exist in your array or not
      if (index !== -1) {
        marital_statuses.splice(index, 1);
      }

      marital_statuses.push("Other");

      // return marital_statuses.sort();
      return marital_statuses;
    },
  },
};

export default list;
