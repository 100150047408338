const passwordRecovery = {
  namespaced: true,

  state: {
    justReseted: false,
  },

  mutations: {
    setJustReseted(state, payload) {
      state.justReseted = payload;
    },

    resetState(state) {
      state.justReseted = false;
    },
  },

  actions: {},

  getters: {},
};

export default passwordRecovery;
